import React, { useState } from "react"
import styled from "styled-components"
import axios from "axios"
import { useTranslation } from "react-i18next"
import { MEDIA_MIN_MEDIUM } from "../../constants"
import { Controller, ErrorMessage, useForm } from "react-hook-form"
import ScrollTo from "react-scroll-into-view"
import Input from "../ui/Input"
import Button from "../ui/Button"
import Loader from "../ui/Loader"

const Container = styled.div`
  width: 100%;
`

const Field = styled(Input)`
  input {
    border: 0.1rem solid ${(p) => p.theme.colors.lightGrey};
    width: 100%;
    text-transform: initial;
  }
`

const SubmitButton = styled(Button)`
  background: ${(p) => p.theme.colors.primary};
  color: ${(p) => p.theme.colors.white};
  margin-top: 1rem;
`

const MessageContainer = styled.div`
  position: relative;
  margin-bottom: 1.5rem;
`

const Message = styled.textarea`
  border: 0.1rem solid ${(p) => p.theme.colors.lightGrey};
  width: 100%;
  height: 18rem;
  margin: 4rem 0 2rem;
  text-transform: initial;
  padding: 2rem;
`

const Validation = styled.div`
  color: ${(p) => p.theme.colors.errors};
  display: block;
  position: absolute;
  border-top: 0.2rem solid ${(p) => p.theme.colors.errors};
  width: 100%;
  padding-top: 0.4rem;
  letter-spacing: 0;
  font-size: 1.3rem;
  bottom: 0;
`

const Response = styled.div`
  border: 0.1rem solid ${(p) => p.theme.colors.lightGrey};
  padding: 1rem 2rem 1rem 5.8rem;
  margin: 3rem auto 0;
  position: relative;
  display: block;

  ${MEDIA_MIN_MEDIUM} {
    margin: 3rem auto 0;
  }

  i {
    left: 2rem;
    font-size: 2.6rem;
    position: absolute;
    color: ${(p) =>
      p.type === "success" ? p.theme.colors.success : p.theme.colors.errors};
  }
`

const Form = () => {
  const { t } = useTranslation("translations")
  const [isLoading, setIsLoading] = useState(false)
  const [response, setResponse] = useState(false)
  const { control, handleSubmit, errors } = useForm({
    mode: "onBlur"
  })

  const onSubmit = (data) => {
    setIsLoading(true)
    axios({
      method: "post",
      url: `https://cestnormal.zendesk.com/api/v2/requests.json`,
      data: {
        request: {
          requester: { name: data.name },
          subject: data.subject,
          recipient: data.email,
          comment: { body: data.message }
        }
      }
    })
      .then((response) => {
        setIsLoading(false)
        setResponse({ type: "success", message: response.data })
      })
      .catch((error) => {
        setIsLoading(false)
        setResponse({ type: "error", message: error.message })
        console.error("There was an error!", error)
      })
  }

  return (
    <Container>
      <Loader isLoading={isLoading} />
      {response && (
        <Response type={response.type}>
          {response.type === "success" ? (
            <p>
              <i className="fal fa-check-circle"></i>
              {t(
                "Thank you for your e-mail. We will get back to you as soon as possible."
              )}
            </p>
          ) : (
            <p>
              <i class="fal fa-exclamation-triangle"></i>{" "}
              {t("Something went wrong, please try again later.")}
            </p>
          )}
        </Response>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          as={Field}
          control={control}
          rules={{
            required: t("This is a required field")
          }}
          type="text"
          name="name"
          label={t("Name")}
          errors={errors}
        />
        <Controller
          as={Field}
          control={control}
          rules={{
            required: t("This is a required field"),
            pattern: {
              value:
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              message: t("Please supply a valid e-mail address")
            }
          }}
          type="email"
          name="email"
          label={t("E-mail")}
          errors={errors}
        />
        <Controller
          as={Field}
          control={control}
          rules={{
            required: t("This is a required field")
          }}
          type="text"
          name="subject"
          label={t("Subject")}
          errors={errors}
        />
        <MessageContainer>
          <Controller
            as={Message}
            control={control}
            rules={{
              required: t("This is a required field")
            }}
            type="textarea"
            name="message"
            errors={errors}
          />
          {errors && (
            <ErrorMessage errors={errors} name="message">
              {({ message }) => <Validation>{message}</Validation>}
            </ErrorMessage>
          )}
        </MessageContainer>
        <ScrollTo selector=".top">
          <SubmitButton hasIcon>
            {t("Submit")} <i className="fas fa-arrow-right"></i>
          </SubmitButton>
        </ScrollTo>
      </form>
    </Container>
  )
}

export default Form
